.Hero {
  width: 100vw;
  height: 80vh;
  margin:0;
}
#paper-canvas {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #1C3E7B;
}

h1 {
  color: #00AA94;
  position: absolute;
  right: 5vw;
  top: 10vh;
  font-size: 5vw;

  text-transform: uppercase;
  font-weight: bold;
  text-align: right;
  line-height: 5vw;




}
h1 > span {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  background: #1C3E7B;
  padding: 0 0.5vw;
  display: inline-block;
}

h1 > span.light {
  font-weight: 300;
}
