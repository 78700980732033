.Projects {
  background: #1C3E7B;
  position: relative;
  width: 100vw;
  display: block;
  text-align: center;
}

.Projects h3 {
  color: white;
}

.project-container {
  position: relative;
  margin: 0 auto;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -ms-perspective: 800px;
  perspective: 800px;
}

.project {
  width: 100%;
  height: 12.375vw;
  max-height: 200px;
  background-color: #cccccc;
  background-position: center;
  background-size: cover;
  /* animation-name: animateRotation;
  animation-iteration-count: infinite; */
  transition: all 0.5s;
  display: block;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-weight: 600;
  color: white;
  background-repeat: no-repeat;
}

.project span {
  opacity: 0;
  text-shadow: 0px 2px 5px #000;
  transition: all 0.5s;
  background: #00AA9499;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.project:hover {
  background-color: #333;
  -webkit-backdrop-filter: grayscale();
  backdrop-filter: grayscale();
  /* filter: grayscale(); */
}

.project:hover span {
  opacity: 1;
}

.Detail {
  max-height: 0;
  opacity: 0.0;
  position: relative;
  width: 100vw;
  display: block;
  text-align: center;
  overflow: hidden;
  transition: all 1s;
}

.Detail h3 {
  margin: 0;
  width: 90%;
}

.Detail h3 .year {
  font-size: 1rem;
  font-weight: 300;
}

.Detail.open {
  max-height: 500vh;
  opacity: 1.0;
}

.Detail .slider {
  height: 56.25vw;
  border-bottom: 10px solid #00AA94;
}

.video-react .video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  margin-left: -2rem !important;
  margin-top: -2rem !important;
  border: 0 !important;
  height: 4rem !important;
  width: 4rem !important;
  border-radius: 3rem !important;
  line-height: 4rem !important;
  font-size: 2.5rem !important;
}

.previousButton, .nextButton {
  background: rgba(128, 128, 128, 0.5) url('../assets/arrow-slider.svg') no-repeat center center / 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 2rem;
  height: 2rem;
  text-indent: -9999px;
  cursor: pointer;
  border-radius: 2rem;
}

.previousButton {
  transform: rotate(180deg) translateY(50%) !important;
}

.previousButton:hover {
  transform: rotate(180deg) translateY(50%) !important;
}

.nextButton:hover {
  transform: translateY(-50%) !important;
}

.Detail .slide {
  background-size: cover;
  background-position: center;
}

.close {
  height: 1.5rem;
  width: 1.5rem;
  right: 0;
  position: absolute;
}

.project-content {
  display: grid;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

.video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3 {
    height: 100%;
}

@media (max-width: 800px) {
  .project-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .project {
    height: 24.75vw;
  }
}

@media (max-width: 576px) {
  .project-container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 6vw;
  }

  .project {
    height: 49.5vw;
  }

  .project-content {
    display: inline-block;
  }
}
