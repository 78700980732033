.About {
  background: #ffffff;
  position: relative;
  display: block;
  text-align: center;
}

.portrait {
  display: block;
  height:70vh;
  width:100vw;
  background-color: #FEFEFE;
  background-image: url('../assets/Portrait-2019.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;

}

.text {
  width:100%;
  -moz-columns:2 250px;
  -webkit-columns:2 250px;
  columns: 2 250px;

  -moz-column-gap:2.5rem;
  -webkit-column-gap:2.5rem;
  column-gap:2.5rem;
}
