@import url('https://fonts.googleapis.com/css?family=Titillium+Web:200,300,400,600,700&display=swap');

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 1.4rem;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: left;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.01rem;
}

.container {
  text-align: left;
  display: inline-block;
  position: relative;
  margin:50px auto 50px auto;
  max-width:1140px;
  width:80vw;

}
