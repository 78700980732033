.Claim {
  background: #00AA94;
  text-align: center;
  color: white;
  overflow: hidden;
  display: block;
}

.words {
  display: inline-block;
  position: relative;
  width: 350vw;
  left: 25vw;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  /* line-height: 19vh; */
  top: 0.15vh;
}

.Claim h2 {
  display: inline-block;
  width: 14.28%;
  min-width: 100px;
  font-size: 4vmin;
  font-weight: 600;
  opacity: 0.4;
  transition: opacity 1.0s;
  margin: 0.5vh 0 0 0;
  text-transform: uppercase;
  box-sizing: content-box;
  line-height: 4vmin;
}

.Claim h2.highlighted {
  opacity: 1.0;
}

.greeting {
  font-size: 3vmin;
  line-height: 3vmin;
  font-weight: 600;
  top: 0.25vmax;
  position: relative;
  display: inline-block;
  margin-top: 1vmin;
}

.Claim img {
  position: relative;
  width: 1.5vh;
  max-width: 40px;
  animation-duration: 0.75s;
  animation-name: upDown;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-top: 1.5vmin;
  margin-bottom: 1vmin;
}

@keyframes upDown {
  0% {
    transform: translateY(0.5vh);
  }

  100% {
    transform: translateY(-0.5vh);
  }
}
