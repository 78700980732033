.App {
}


.Footer {
  width:100vw;
  height:10vh;
  background-color: #00AA94;
  position: relative;
  display: block;
  text-align: center;
  font-size: 0.85rem;
  line-height: 1rem;
  color:#1C3E7B;
}

.Footer .container {
  margin-top: 2.5vh;
}

a {
  text-decoration: none;
  font-weight:bold;
  color:inherit;
}
